.toc {
  height:100%;
}

html {
  height: auto !important;
  overflow-y: auto !important;
}

.tocLinkItem a:hover {
  text-decoration: none !important;
}

.jsTocContent {
  font-size: 15px;
}

.jsTocContent h1,h2,h3 {
  font-weight: 400;
  color: #343a40;
}

.jsTocContent hr {
  border: 1px solid #f4f6f7;
}

.jsTocContent p a {
  color: #0099e5;
}

.jsTocContent code {
  color: #000;
  padding-right: 3px;
  padding-left: 3px;
  background-color: #ddd;
}