@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'base';
@import 'node_modules/normalize.css/normalize';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
#root > [data-reactroot] {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// .release-note .CodeMirror {
//   height: 100% !important;
//   background-color: transparent;
//   cursor: text;
// }
//  .version-header .CodeMirror {
//   height: 100px !important;
//   border: 1px solid #ddd;
//   cursor: text;
//   overflow-x: scroll !important;
// }
// .release-note .ReactCodeMirror {
//   flex: 1;
//   min-height: 3rem;
//   background-color: #F7F3F7;
// }
.hover-link {
  font-weight: bold;
  text-decoration: none;
}
.hover-danger:hover {
  color: $danger !important;
  transition-duration: 0.5s;
  text-decoration: none;
}
.hover-info:hover {
  color: $info !important;
  transition-duration: 0.5s;
  text-decoration: none;
}
.hover-success:hover {
  color: $success !important;
  transition-duration: 0.25s;
  text-decoration: none;
}
.input-group-text.btn.btn-sm.btn-link:hover {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
}

.table .text {
  position: relative;
}

.table .text span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 10px;
}
.text:before {
  content: '';
  display: inline-block;
}

.Select--multi .Select-value-icon {
  color: white !important;
  border: 1px transparent solid !important;
}
.Select--multi .Select-value-icon:hover {
  color: white !important;
}
.dim-button {
  opacity: 0.5;
  border-color: transparent;
}
.dim-button:hover {
  opacity: 1;
}
.value-option {
  line-height: 1rem !important;
  margin-left: 2px;
  margin-top: 2px;
}
.value-option:last {
  margin-bottom: 2px;
}
.pr-details {
  margin-top: -1px;
  font-size: 0.75rem;
}

a {
  color: black;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
}

.nav-pills .nav-link {
  border-radius: 0;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.nav-pills .nav-link.active {
  border: 1px solid $blue;
}

.form-control {
  box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075);
}
.va-middle {
  vertical-align: middle !important;
}

.badge-outline-danger {
  border: 1px solid $danger;
  color: $danger;
  background-color: transparent;
  font-weight: normal;
}
h3 .badge-outline-danger {
  margin-top: 0.2rem;
  padding-top: 0.2rem;
}

.markdown-container img {
  width: 350px !important;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #dc3545;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}
.note-footer:last-child {
  margin-bottom: 0.5rem;
}

// .CodeMirror-sizer{
//   position:absolute;
//   left:30px;
//   right: 0px;
//   bottom: 0px;
//   top: 0px;
// }
// .CodeMirror-scroll div:nth-child(2){
//   width:auto !important;
//   left:0px;
//   right:0px;
// }

.list-group-item.active a {
  color: white !important;
}

.list-group-item.active .text-muted {
  color: whitesmoke !important;
}

.text-info-bright {
  color: lighten($info, 30%) !important;
  opacity: 1 !important;
}
