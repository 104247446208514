/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/

//@import 'node_modules/bootstrap/scss/bootstrap';


$purple: #6f42c1;
.text-purple {
  color: $purple !important;
}
