label{
  font-weight: 600;
}
.reactNumericInput{
  width: 50px !important;
}
.dragHandle{
  color: #777;
  padding: 0px 15px;
  cursor:ns-resize;
  width:41px;
}

.versionNoteContainer {
  display:flex;
  width:100%;
  align-items: center;
  align-content: center;
  border: 0px none #ddd;
  // border-left:0px none;
  // border-right:0px none;
  background-color: white;
  margin-bottom: -1px;
}
.versionNoteContainer:nth-child(odd){
  background-color: #f9f5f7;
}


.notesContainer {
  padding-top: 1.5rem;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
}