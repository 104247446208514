@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

$sm-breakpoint: map-get($grid-breakpoints, "sm");

.parcelTitle {
  color: $info;
  font-size: 3.5rem;
}

.parcelSubtitle {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.freeBannerContent {
  font-weight: 500;
}

@media (min-width: $sm-breakpoint) {
  .parcelTitle {
    font-size: 5.5rem;
  }

  .parcelSubtitle {
    font-size: 2rem;
  }

  .freeBannerContent {
    font-size: 1.25rem;
  }
}