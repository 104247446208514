@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

$border-1-percent: 10% !default;
$border-2-percent: 15% !default;

.ribbon {
  position: relative;
  background: $primary;
  color: $white;
  display: inline-block;
  text-align: center;
  padding: 0.5em 1.5em;
  margin: 1.5em 2rem 2.5em;

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1em;
    border: 1.5em solid darken($primary, $border-1-percent);
    z-index: -1;
  }

  &:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent !important;
  }

  &:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent !important;
  }

  .ribbonContent {
    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
      border-style: solid;
      border-color: darken($primary, $border-2-percent) transparent transparent transparent;
      bottom: -1em;
    }

    &:before {
      left: 0;
      border-width: 1em 0 0 1em;
    }

    &:after {
      right: 0;
      border-width: 1em 1em 0 0;
    }
  }

  &:global(.bg-success) {
    background-color: $success !important;

    &:before, &:after {
      border-color: darken($success, $border-1-percent);
    }

    .ribbonContent:before, .ribbonContent:after {
      border-top-color: darken($success, $border-2-percent);
    }
  }

  &:global(.bg-info) {
    background-color: $info !important;

    &:before, &:after {
      border-color: darken($info, $border-1-percent);
    }

    .ribbonContent:before, .ribbonContent:after {
      border-top-color: darken($info, $border-2-percent);
    }
  }

  &:global(.bg-secondary) {
    background-color: $secondary !important;

    &:before, &:after {
      border-color: darken($secondary, $border-1-percent);
    }

    .ribbonContent:before, .ribbonContent:after {
      border-top-color: darken($secondary, $border-2-percent);
    }
  }

  &:global(.bg-warning) {
    background-color: $warning !important;

    &:before, &:after {
      border-color: darken($warning, $border-1-percent);
    }

    .ribbonContent:before, .ribbonContent:after {
      border-top-color: darken($warning, $border-2-percent);
    }
  }

  &:global(.bg-danger) {
    background-color: $danger !important;

    &:before, &:after {
      border-color: darken($danger, $border-1-percent);
    }

    .ribbonContent:before, .ribbonContent:after {
      border-top-color: darken($danger, $border-2-percent);
    }
  }
}
