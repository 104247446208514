@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
:global {
  .parcel-version {
    border: 2px solid $danger;
    padding: 0.5rem;
    display: inline-block;
    border-radius: 8px;
    color: $danger;
    font-weight: 700;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .parcel-change-list {
    list-style-type: none;
    padding: 0px;
  }

  .parcel-change-item {
    display: flex;
  }
  .parcel-badge {
    align-self: start;
    width: 75px;
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0.3rem;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
    margin: 0.2rem 1rem;
    color: white;
    text-transform: uppercase;
  }
  .parcel-change-note {
    flex: 1;
    align-self: center;
  }
  .parcel-tag-New .parcel-badge {
    background-color: $success;
  }
  .parcel-tag-Improved .parcel-badge {
    background-color: $info;
  }
  .parcel-tag-Fixed .parcel-badge {
    background-color: darken($info, 20%);
  }
}
