.markdownEditor{
  opacity: 0.0;
}
.markdownEditor:focus, .markdownEditor:active{
  opacity: 1.0;
}
.markdownRender{
  font-family: sans-serif;
  font-size: 100%;
  height: auto;
  line-height: 1.15;
  margin: 0;
  background-color: transparent;
  border: 0px none;
  padding: 0.5rem;
  min-height:50px !important;
}
